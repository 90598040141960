<template>
  <div class="col-md-12" v-if="Object.keys(purchase_request).length !== 0">
    <div class="card">
      <div class="card-body">
        <a-steps :current="current" size="small">
          <a-step title="PR Request" :description="purchase_request.product_request_statuses.filter(e => e.status === 'STORE_IN_CHARGE_PENDING').length > 0 ? customDate(purchase_request.product_request_statuses.find(e => e.status === 'STORE_IN_CHARGE_PENDING').created_at) : ''" />
          <a-step title="Admin"  :description="purchase_request.product_request_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0 ? customDate(purchase_request.product_request_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) : ''"  />
          <a-step title="HQ"  :description="purchase_request.product_request_statuses.filter(e => e.status === 'HQ_APPROVED').length > 0 ? customDate(purchase_request.product_request_statuses.find(e => e.status === 'HQ_APPROVED').created_at) : ''"  />
          <a-step title="Top Management"  :description="purchase_request.product_request_statuses.filter(e => e.status === 'TOP_MANAGEMENT_APPROVED').length > 0 ? customDate(purchase_request.product_request_statuses.find(e => e.status === 'TOP_MANAGEMENT_APPROVED').created_at) : ''"  />
          <a-step title="Procurement"  :description="purchase_request.product_request_statuses.filter(e => e.status === 'PROCUREMENT_APPROVED').length > 0 ? customDate(purchase_request.product_request_statuses.find(e => e.status === 'PROCUREMENT_APPROVED').created_at) : ''"  />
        </a-steps>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'purchase_request_header_status',
  props: ['current', 'purchase_request'],
  methods: {
    customDate(date) {
      return moment(date).format('LLL')
    },
  },
}
</script>

<style scoped>

</style>
