<template>
  <div class="row" v-if="Object.keys(purchase_request).length !== 0">
    <purchase-request-header-status :current="current" :purchase_request="purchase_request"></purchase-request-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="font-weight-bold">PR Number</label>
                <p>{{ purchase_request.pr_number }}</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="font-weight-bold">Delivery On Before</label>
                <p>{{ purchase_request.delivery_date }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <form action="">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Unit</th>
                  <th>Qty</th>
                  <th>Unit Price</th>
                  <th>Sub Total</th>
                  <th>Total</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>{{ product.product.name }}</td>
                  <td>{{ product.product.unit }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ product.unit_price }}</td>
                  <td>{{ product.sub_total }}</td>
                  <td>{{ product.total }}</td>
                  <td>{{ product.remarks }}</td>
                </tr>
                <!--  Total, Others, shipping-->
                <tr>
                  <th style="text-align: right" colspan="5">SUBTOTAL</th>
                  <td>{{ purchase_request.subtotal }}</td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="5">OTHER</th>
                  <td>{{ purchase_request.others }}</td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="5">TOTAL</th>
                  <td>{{ purchase_request.grand_total }}</td>
                </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import purchaseRequestHeaderStatus from '@/views/purchaseRequest/partial/purchase_request_header_status'

export default {
  name: 'purchaseRequestDetails',
  components: { purchaseRequestHeaderStatus },
  data() {
    return {
      products: [],
      purchase_request: {},
      loader: false,
      flag: false,
      loading: false,
      mailLoader: false,
      current: 0,
    }
  },
  mounted() {
    this.getPurchaseRequest()
  },
  methods: {
    getPurchaseRequest() {
      this.loading = true
      const purchaseRequestId = this.$route.params.purchase_request_id
      apiClient.get('api/purchase-requests/' + purchaseRequestId)
        .then(response => {
          if (!response.data.error) {
            this.loading = false
            const data = response.data
            this.products = data.products
            this.purchase_request = data.purchase_request
            this.current = data.max_purchase_request_status
          } else {
            this.loading = false
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
